import React, { useEffect, useState } from "react";
import styled from "styled-components";

import mailIcon from "../../../assets/images/services/icons/mail.svg";
import callIcon from "../../../assets/images/services/icons/telephone.svg";

import { accountsConfig } from "../../../axiosConfig";
import RentalsEnqiry from "../modal/RentalsEnqiry";

import { useNavigate } from "react-router-dom";
import pdfFile from '../../../assets/data/data.pdf'

function ServiceSection() {
  const navigate = useNavigate()
  const [show, setshow] = useState("");
  const [services, setservices] = useState([]);
  const [array, setarray] = useState([]);
  const [state, setstate] = useState(false);
  const [policy, setPolicy] = useState({})
  const handleClick = () => {
    window.open(pdfFile, '_blank');
  };
  const fetchPolicyData = async () => {
    try {
      const res = await accountsConfig.get("web/classic-warranty-policy/")
      const { data, StatusCode } = res.data.app_data;
      if (StatusCode === 6000) {
        setPolicy(data)
      } else {
        setPolicy({})
      }
    } catch (error) {
      console.log(error);

      setPolicy({})
    }
  }

  useEffect(() => {
    accountsConfig.get("projects/list-services/").then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setservices(data);
        setstate(!state);
        fetchPolicyData()
      }
    });

  }, []);

  useEffect(() => {
    const tempArray = [];
    let currentAdded = 0;
    for (let i = 0; i < services?.length; i++) {
      const Pattern1 = ["Gr", "Wh"];
      const Pattern2 = ["Wh", "Gr"];

      if (i % 2 === 0) {
        if (currentAdded === 1) {
          tempArray.push(...Pattern2);
          currentAdded = 2;
        } else {
          tempArray.push(...Pattern1);
          currentAdded = 1;
        }
      }
    }
    setarray(tempArray);
  }, [state]);

  const handleViewMore = (index) => {
    setshow(index);
  };

  const handleViewLess = () => {
    setshow("");
  };
  console.log(policy);

  return (
    <Section>
      <Wrapper className="wrapper">
        <Container>
          <BoxContainer>
            <Box>
              <Title>
                <Classic>OUR</Classic>SERVICES
              </Title>
              <Line />
              <SubTitle>BEST BUILDERS IN KOCHI</SubTitle>
            </Box>
            <Box>
              <Para>
                At Classic Homes, we transcend traditional construction by
                offering personalised solutions designed to enhance your
                lifestyle. Explore our meticulously curated services, tailored
                to meet your every need and aspiration. Our team diligently
                ensures that every need of our beloved homeowners is met and
                tended to.
              </Para>
            </Box>
          </BoxContainer>
          <BoxContainer className="service-container">
            {services.map((obj, index) => {
              return (
                <Box className="card">
                  <Img className={array[index] === "Gr" && "cl-grey"}>
                    <img src={obj.image} alt="" />
                  </Img>
                  <Div className={array[index] === "Gr" && "cl-grey"}>
                    <BoxTitle>{obj.name}</BoxTitle>
                    {obj.description.length > 450 ? (
                      <BoxDescContainer>
                        <BoxDesc
                          dangerouslySetInnerHTML={{
                            __html:
                              show === index
                                ? obj.description
                                : obj.description?.slice(0, 450) + "...",
                          }}
                        ></BoxDesc>
                        {show === index ? (
                          <ViewLess onClick={() => handleViewLess()}>
                            Less
                          </ViewLess>
                        ) : (
                          <ViewMore onClick={() => handleViewMore(index)}>
                            Read more
                          </ViewMore>
                        )}
                      </BoxDescContainer>
                    ) : (
                      <BoxDesc
                        dangerouslySetInnerHTML={{ __html: obj.description }}
                      >
                        {/* {obj.description} */}
                      </BoxDesc>
                    )}
                    <Btns>
                      {!(obj.name === "Rentals & Resale" || obj.name === "Home Care Services") && (
                        <CallBtn
                          href="tel:+91 9072372377"
                          className={array[index] === "Gr" ? "cl-grey" : ""}
                        >
                          <Span>Call Now</Span>
                          <Icon>
                            <img src={callIcon} alt="" />
                          </Icon>
                        </CallBtn>
                      )}

                      {obj.name === "Rentals & Resale" || obj.name === "Home Care Services" ? (
                        <>
                          {obj.name === "Home Care Services" && <PolicyBtn href={policy?.files ? policy?.files : ""} target="_blank">
                            <Span className="policy">Classic Warranty Policy</Span>
                          </PolicyBtn>}
                          <EnqBtn onClick={() => obj.name === "Home Care Services" ? navigate('/home-care-services') : obj.name === "Rentals & Resale" ? navigate('/rentals-&-resale') : ""}>
                            <Span className="enq">Enquiry</Span>
                          </EnqBtn>
                          {obj.name === "Home Care Services" && <PolicyBtn className="maintence" onClick={handleClick}>
                            <Span className="policy">Periodic Maintenance</Span>
                          </PolicyBtn>}

                        </>
                      ) : (
                        <MailBtn href="mailto:sales@classichomes.in">
                          <Span className="mail">Email</Span>
                          <Icon>
                            <img src={mailIcon} alt="" />
                          </Icon>
                        </MailBtn>
                      )
                      }
                    </Btns>
                  </Div>
                </Box>
              );
            })}
          </BoxContainer>
        </Container>
      </Wrapper>
      <RentalsEnqiry />
    </Section>
  );
}

export default ServiceSection;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 130px;
  margin-top: 130px;

  @media (max-width: 900px) {
    row-gap: 64px;
  }

  @media screen and (max-width:580px) {
     margin-top: 60px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 130px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 130px;

  @media (max-width: 900px) {
    row-gap: 64px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 130px;
  column-gap: 40px;

  &.service-container {
    column-gap: 60px;
  }

  @media (max-width: 580px) {
    width: 80%;
    margin: 0 auto;

    &.service-container {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    row-gap: 64px;
  }

  @media (max-width: 1200px) {
    column-gap: 100px !important;
  }

  @media (min-width: 2200px) {
    column-gap: 130px !important;
  }
`;

const Box = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;

  &.card {
    gap: 0;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 2200px) {
    width: 45%;
  }
`;

const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  gap: 10px;

  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 580px) {
    text-align: center;
    font-size: var(--heading-fz);
    justify-content: center;
    margin: 0 auto;
  }
`;

const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;

  @media screen and (max-width: 580px) {
    margin: 0 auto;
  }
`;

const SubTitle = styled.div`
  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  color: #000000;
  text-transform: uppercase;

  @media screen and (max-width: 580px) {
    text-align: center;
    width: 100%;
  }
`;

const Classic = styled.div`
  color: #606060;
  font-size: 65px;

  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 580px) {
    text-align: center;
    font-size: var(--heading-fz);
  }
`;

const Para = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  text-align: justify;

  @media (max-width: 900px) {
    text-align: justify;
  }
`;

const Img = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 170px;

  img {
    margin: 0 30px;
    z-index: 1;
    height: 170px;
    width: auto;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    background-color: #ffff;
  }

  &.cl-grey {
    &::after {
      background-color: #f4f4f4;
    }
  }

  @media (max-width: 400px) {
    img {
      margin: 0 auto;
      width: 100%;
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background: #ffff;

  &.cl-grey {
    background-color: #f4f4f4;
  }

  @media (max-width: 400px) {
    padding: 25px;
    box-sizing: border-box;
  }
  @media (max-width: 375px) {
    padding: 20px;
    box-sizing: border-box;
  }
`;

const BoxTitle = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.38px;
  min-height: 30px;
`;

const BoxDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BoxDesc = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  text-align: justify;

  /* height: calc(22px + 280px); */
  /* overflow: hidden; */

  /* &.open{
   height: auto !important;
  } */

  /* &.extra{
    height: 280px;
  } */
`;

const Btns = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const MailBtn = styled.a`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background-color: #eb6502;
  border: none;
  outline: none;
  width: 150px;

  @media (max-width: 500px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const EnqBtn = styled.p`
  display: flex;
  gap: 15px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 12px 24px;
  background-color: #eb6502;
  border: none;
  outline: none;
  width: 150px;

  @media (max-width: 500px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const CallBtn = styled.a`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border: 1px solid #eb6502;
  background: #ffff;
  width: 150px;

  &.cl-grey {
    background: #f4f4f4;
  }

  @media (max-width: 500px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const PolicyBtn = styled.a`
cursor: pointer;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  border: 1px solid #eb6502;
  background: #ffff;
  width: 150px;

  &.cl-grey {
    background: #f4f4f4;
  }

  @media (max-width: 500px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const Span = styled.a`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.mail {
    color: #ffff;
  }
  &.enq {
    color: #ffff;
  }
  &.policy {
    font-size: 14px;
  }
`;

const Icon = styled.div`
  img {
    width: 16px;
    color: #706e6c;
    height: 16px;
  }
`;

const ViewMore = styled.div`
  color: #eb6502;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
  text-align: end;
  cursor: pointer;
`;

const ViewLess = styled.div`
  color: #eb6502;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
  text-align: end;
  cursor: pointer;
`;
