import React from 'react'
import Helmet from '../includes/Helmet'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import RentalsAndResaleEnquiry from '../includes/rentaland resale/RentalsAndResaleEnquiry'

function RentalsAndResale() {
  return (
    <>
      <Helmet titleData={"Home Care Services | Classic homes"} descriptionData={"HomeCareServices"} />
      <Header />
      <RentalsAndResaleEnquiry/>
      <Footer />
    </>
  )
}

export default RentalsAndResale