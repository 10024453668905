import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import imageIcon from '../../../assets/images/homecareservices/imagesicons.svg';
import videoIcon from '../../../assets/images/homecareservices/videoicon.svg';
import closeIcon from '../../../assets/images/homecareservices/close.svg';
import { accountsConfig } from '../../../axiosConfig';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';


function RentalsAndResaleEnquiry() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ name: "", number: "", project: "", year_of_purchase: "", type: "", number_of_bedrooms: "", total_carpet_area: "", price: "", negotiable: "" });
    const [error, setError] = useState({ name: "", number: "", project: "", year_of_purchase: "", type: "", number_of_bedrooms: "", total_carpet_area: "", price: "", negotiable: "" });
    const [apartmentsVillas, setApartmentsVillas] = useState([])
    const type = [
        { value: "Furnished", label: "Furnished" },
        { value: "Semi Furnished", label: "Semi Furnished" },
        { value: "Non-Furnished", label: "Non-Furnished" },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value
        }));
        setError((prev) => ({
            ...prev,
            [name]: ""
        }));
    };

    const handleProjectSelectChange = (selectedOption) => {
        setData((prev) => ({
            ...prev,
            project: selectedOption ? selectedOption.value : ''
        }));
        setError((prev) => ({
            ...prev,
            project: ""
        }));
    };

    const handlenegotiableSelectChange = (selectedOption) => {
        setData((prev) => ({
            ...prev,
            type: selectedOption ? selectedOption.value : ''
        }));
        setError((prev) => ({
            ...prev,
            type: ""
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation logic
        const newErrors = {};
        if (!data.name) newErrors.name = "Name is required";
        if (!data.number) newErrors.number = "Number is required";
        if (!data.project) newErrors.project = "Project is required";
        if (!data.year_of_purchase) newErrors.year_of_purchase = "Year of property purchase is required";
        if (!data.type) newErrors.type = "Type is required";
        if (!data.number_of_bedrooms) newErrors.number_of_bedrooms = "Number Of Bedrooms is required";
        if (!data.total_carpet_area) newErrors.total_carpet_area = "Total Carpet Area is required";
        if (!data.price) newErrors.price = "Price is required";
        if (!data.negotiable) newErrors.negotiable = "Negotiable is required";

        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
        } else {
            setLoading(true);
            try {
                const res = await accountsConfig.post("web/sales-and-service-enquiry/", data);

                // Handle the response data if needed
                if (res.data.app_data.StatusCode === 6000) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Success",
                        showConfirmButton: false,
                        timer: 1500,
                        iconColor: "#0e2d5e",
                    });
                    navigate('/services')
                    console.log("Form submitted successfully:", res.data);
                } else {
                    // Handle failure
                    console.log("Submission failed:", res.data);
                }
            } catch (error) {
                console.log("Error submitting form:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const fetchAppartmentAndvillas = async () => {
        try {
            let apartmentsData = [];
            let villasData = [];

            const res = await accountsConfig.get("projects/list-type-project/?project_type=apartment");
            if (res.data.app_data.StatusCode === 6000) {

                apartmentsData = res.data.app_data.data.map(appart => ({
                    value: appart.id,
                    label: appart.name
                }));
            }

            const res2 = await accountsConfig.get("projects/list-type-project/?project_type=villas");
            console.log(res2, 'sifandaxo');

            if (res2.data.app_data.StatusCode === 6000) {
                villasData = res2.data.app_data.data.map(villas => ({
                    value: villas.id,
                    label: villas.name
                }));
            }

            const combinedData = [...apartmentsData, ...villasData];

            setApartmentsVillas(combinedData);
        } catch (error) {
            console.error("Error fetching apartments and villas:", error);
        }
    };


    useEffect(() => {
        fetchAppartmentAndvillas()
    }, [])


    return (
        <Section>
            <Wrapper className="wrapper">
                <Container>
                    <BoxContainer>
                        <Box>
                            <Title>
                                <Classic>Rentals </Classic>& Resale
                            </Title>
                            <Line />
                        </Box>
                    </BoxContainer>
                    <FormContainer onSubmit={handleSubmit}>
                        <Form>
                            <FormItem>
                                <Name>
                                    Full Name<span>*</span>
                                </Name>
                                <TextInput
                                    name="name"
                                    type="text"
                                    value={data?.name}
                                    onChange={handleChange}
                                />
                                {error.name && <Error>{error.name}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Number <span>*</span>
                                </Name>
                                <TextInput
                                    name="number"
                                    type="number"
                                    value={data?.number}
                                    onChange={handleChange}
                                />
                                {error.number && <Error>{error.number}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Name of Project <span>*</span>
                                </Name>
                                <Select
                                    onChange={handleProjectSelectChange}
                                    options={apartmentsVillas}
                                    placeholder="Name of Project"
                                    isSearchable
                                    name="project"
                                />

                                {error.project && <Error>{error.project}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Year of property Purchase <span>*</span>
                                </Name>
                                <TextInput
                                    name="year_of_purchase"
                                    type="number"
                                    value={data?.year_of_purchase}
                                    onChange={handleChange}
                                />
                                {error.year_of_purchase && <Error>{error.year_of_purchase}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Type <span>*</span> (Furnished/Semi Furnished/Non-Furnished)
                                </Name>
                                <Select
                                    onChange={handlenegotiableSelectChange}
                                    options={type}
                                    placeholder="type"
                                    isSearchable
                                    name="type"
                                />
                                {error.type && <Error>{error.type}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Number of Bedrooms <span>*</span>
                                </Name>
                                <TextInput
                                    name="number_of_bedrooms"
                                    type="text"
                                    value={data?.number_of_bedrooms}
                                    onChange={handleChange}
                                />
                                {error.number_of_bedrooms && <Error>{error.number_of_bedrooms}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Total Carpet area <span>*</span>
                                </Name>
                                <TextInput
                                    name="total_carpet_area"
                                    type="text"
                                    value={data?.total_carpet_area}
                                    onChange={handleChange}
                                />
                                {error.total_carpet_area && <Error>{error.total_carpet_area}</Error>}
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Asking Price  <span>*</span>
                                </Name>
                                <TextInput
                                    name="price"
                                    type="text"
                                    value={data?.price}
                                    onChange={handleChange}
                                />
                                {error.price && <Error>{error.price}</Error>}
                            </FormItem>
                            <FormItemCheckbox>
                                <Name>
                                    Negotiable  <span>*</span>
                                </Name>
                                <div className="flex">
                                    <div className="flex">
                                        <SmallTitle>Yes</SmallTitle>
                                        <Checkbox
                                            type='radio'
                                            name='negotiable'
                                            value="Yes"
                                            checked={data?.negotiable === "Yes"}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex">
                                        <SmallTitle>No</SmallTitle>
                                        <Checkbox
                                            type='radio'
                                            name='negotiable'
                                            value="No"
                                            checked={data?.negotiable === "No"}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                {error.negotiable && <Error>{error.negotiable}</Error>}
                            </FormItemCheckbox>
                            <SubmitBtn type='submit'>
                                {loading ? <ClipLoader
                                    color='#ffffff'
                                    loading={loading}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> : "Send Request"}
                            </SubmitBtn>
                        </Form>
                    </FormContainer>
                </Container>
            </Wrapper>
        </Section>
    )
}

export default RentalsAndResaleEnquiry;


// Styles remain the same as in your code

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 130px;
  margin-top: 60px;

  @media (max-width: 900px) {
    row-gap: 64px;
  }

  @media screen and (max-width:580px) {
     margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 130px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 900px) {
    row-gap: 64px;
  }
  @media (max-width: 580px) {
    gap: 10px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 130px;
  column-gap: 40px;

  &.service-container {
    column-gap: 60px;
  }

  @media (max-width: 580px) {
    width: 80%;
    margin: 0 auto;

    &.service-container {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    row-gap: 64px;
  }

  @media (max-width: 1200px) {
    column-gap: 100px !important;
  }

  @media (min-width: 2200px) {
    column-gap: 130px !important;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;

  &.card {
    gap: 0;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 2200px) {
    width: 100%;
  }
  @media (max-width: 580px) {
    gap: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  gap: 10px;

  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 580px) {
    text-align: center;
    font-size: var(--heading-fz);
    justify-content: center;
    margin: 0 auto;
    font-size: 20px;
  }
`;

const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;

  &.images {
    margin-top: 6px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto;
  }
`;



const Classic = styled.div`
  color: #606060;
  font-size: 65px;

  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 580px) {
    text-align: center;
    font-size: 20px;
    
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width:768px){
    flex-direction: column;
  }
`

const RightSide = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  padding: 20px;
  @media(max-width:480px){
      width: auto;
    }
`

const Form = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap:14px;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 20;
    @media(max-width:480px){
      padding: 30px;
      width: auto;
    }
`;

const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    width:45%;
    gap: 10px;

    &.fw{
        width: 100%;
    }

    @media(max-width:580px){
      width: 100%;
    }
`;
const FormItemCheckbox = styled.div`
    width: 50%;
    .flex{
        display: flex;
        gap: 10px;
    }
`
const Name = styled.div`
    color: #706E6C;
    text-align: justify;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    span{
      color: red;
    }
`;

const SmallTitle = styled.p`
    color: #706E6C;
    text-align: justify;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
`

const TextInput = styled.input`
  outline:none;
  border: none;
  border-bottom: 1px solid #606060;
  background-color: transparent;
`;

const Checkbox = styled.input`
  outline:none;
  border: none;
  border-bottom: 1px solid #606060;
  resize: vertical;
  background-color: transparent;
`;


const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: -6px;
`

const SubmitBtn = styled.button`
  padding: 16px 27px;
  background: #EB6502;
  outline: none;
  border: none;
  color: #FFF;
  font-family: 'gellix-regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; 
  cursor: pointer;
  float: right;
  margin-top: 20px;
`;