import axios from "axios";

// // //DEMO SERVER
// export const accountsConfig = axios.create({
//   baseURL: "https://homes-dashboard.accoladesweb.com/api/v1/",
// });


// Live SERVER
export const accountsConfig = axios.create({
  baseURL: "https://backend.classichomes.in/api/v1/",
});


// LOCAL SERVERP
// export const accountsConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/",
// });

